import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Button from "@mui/material/Button";

const AuthButton = () => {
  const { isAuthenticated, loginWithRedirect, logout } = useAuth0();
  return isAuthenticated ? (
    <Button
      color="inherit"
      style={{
        color: "black",
        fontWeight: "bold",
        borderRadius: 50,
        "&:hover": {
          color: "gray",
        },
      }}
      onClick={() => logout({ returnTo: window.location.origin })}
    >
      Sign Out
    </Button>
  ) : (
    <Button
      color="inherit"
      style={{
        color: "black",
        fontWeight: "bold",
        borderRadius: 50,
        "&:hover": {
          color: "gray",
        },
      }}
      onClick={() => loginWithRedirect()}
    >
      Sign In
    </Button>
  );
};

export default AuthButton;