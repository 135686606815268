import React from "react";
import { Box } from "@mui/material";

const ChildComponentLayout = ({ children }) => {
  return (
    <Box
      sx={{
        padding: 4, 
        maxWidth: "1200px", 
        margin: "auto",
        width: "100%", 
      }}
    >
      {children}
    </Box>
  );
};

export default ChildComponentLayout;
