import React, { useState, useEffect } from "react";
import { TextField } from "@mui/material";
import useDebounce from "../../hooks/useDebounce";

const SearchInput = ({ onSearch, placeholder = "Search...", delay = 300 }) => {
  const [input, setInput] = useState("");
  const debouncedSearchTerm = useDebounce(input, delay);

  useEffect(() => {
    if (debouncedSearchTerm || debouncedSearchTerm === "") {
      onSearch(debouncedSearchTerm);
    }
  }, [debouncedSearchTerm, onSearch]);

  return (
    <TextField
      fullWidth
      variant="outlined"
      value={input}
      onChange={(e) => setInput(e.target.value)}
      placeholder={placeholder}
      sx={{ mb: 2 }}
    />
  );
};

export default SearchInput;
