import React, { useState, useCallback, useMemo, useRef, useEffect } from "react";
import { useFacilities } from "../../context/FacilitiesContext";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Container,
  CircularProgress,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
  Box,
  Skeleton,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ClearIcon from '@mui/icons-material/Clear';
import { keyframes } from '@mui/system';

// Define the gradient animation
const gradientMove = keyframes`
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
`;

// Custom loading placeholder component
const LoadingPlaceholder = React.memo(() => (
  <Box
    sx={{
      height: '48px',
      background: 'linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)',
      backgroundSize: '200% 100%',
      animation: `${gradientMove} 1.5s infinite linear`,
      borderRadius: '4px',
    }}
  />
));

const FacilityRow = React.memo(({ facility }) => (
  <TableRow>
    <TableCell>{facility.name}</TableCell>
    <TableCell>{facility.address_line_1}</TableCell>
    <TableCell>{facility.city}</TableCell>
    <TableCell>{facility.facility_number}</TableCell>
    <TableCell>{facility.ft_name}</TableCell>
  </TableRow>
));

const FacilitiesContent = () => {
  const { facilities, loading, error } = useFacilities();
  const [expandedItems, setExpandedItems] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [visibleRange, setVisibleRange] = useState({ start: 0, end: 20 });
  const containerRef = useRef(null);
  const observerRef = useRef(null);

  const handleToggle = useCallback((facilityId) => {
    setExpandedItems((prev) => ({
      ...prev,
      [facilityId]: !prev[facilityId],
    }));
  }, []);

  const handleSearchChange = useCallback((event) => {
    setSearchTerm(event.target.value);
  }, []);

  const handleClearSearch = useCallback(() => {
    setSearchTerm("");
    setVisibleRange({ start: 0, end: 20 }); // Reset visible range
  }, []);

  const filteredFacilities = useMemo(() => {
    if (!searchTerm) return facilities;
    const lowerSearchTerm = searchTerm.toLowerCase();
    return facilities.filter(facility =>
      facility.name.toLowerCase().includes(lowerSearchTerm) ||
      facility.facility_number.toLowerCase().includes(lowerSearchTerm) ||
      facility.children.some(child =>
        child.name.toLowerCase().includes(lowerSearchTerm) ||
        child.facility_number.toLowerCase().includes(lowerSearchTerm)
      )
    );
  }, [facilities, searchTerm]);

  const setupObserver = useCallback(() => {
    if (observerRef.current) {
      observerRef.current.disconnect();
    }

    observerRef.current = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const index = parseInt(entry.target.dataset.index, 10);
          setVisibleRange((prev) => ({
            start: Math.max(0, index - 10),
            end: Math.min(filteredFacilities.length, index + 20),
          }));
        }
      });
    }, { root: null, rootMargin: "200px 0px", threshold: 0 });

    const container = containerRef.current;
    if (container) {
      Array.from(container.children).forEach((child) => {
        observerRef.current.observe(child);
      });
    }
  }, [filteredFacilities.length]);

  useEffect(() => {
    setupObserver();
    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, [setupObserver]);

  useEffect(() => {
    // Ensure the first items are always visible
    setVisibleRange((prev) => ({
      start: 0,
      end: Math.max(prev.end, 20),
    }));
  }, [filteredFacilities]);

  const renderFacilityRow = useCallback((facility, index) => {
    if (index < visibleRange.start || index >= visibleRange.end) {
      return <LoadingPlaceholder />;
    }
    return (
      <Accordion
        expanded={expandedItems[facility.id]}
        onChange={() => handleToggle(facility.id)}
        disableGutters
        elevation={0}
        sx={{
          '&:before': {
            display: 'none',
          },
          '&.Mui-expanded': {
            margin: 0,
          },
        }}
      >
        <AccordionSummary 
          expandIcon={<ExpandMoreIcon />}
          sx={{
            '&:hover': {
              backgroundColor: 'action.hover',
            },
          }}
        >
          <Typography>
            {facility.name} (ID#: {facility.facility_number})
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {facility.children.length > 0 ? (
            <TableContainer component={Paper} elevation={0}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Facility</TableCell>
                    <TableCell>Address</TableCell>
                    <TableCell>City</TableCell>
                    <TableCell>Facility ID</TableCell>
                    <TableCell>Facility Type</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {facility.children.map((child) => (
                    <FacilityRow key={child.id} facility={child} />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Typography variant="body2">No child facilities</Typography>
          )}
        </AccordionDetails>
      </Accordion>
    );
  }, [visibleRange, expandedItems, handleToggle]);

  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">Error loading facilities.</Typography>;

  return (
    <Container maxWidth="lg" sx={{ mt: 4, height: '100%' }}>
      <Typography variant="h4" align="center" sx={{ mb: 4, fontWeight: "bold" }}>
        Facilities
      </Typography>
      <TextField
        fullWidth
        variant="outlined"
        placeholder="Search facilities..."
        value={searchTerm}
        onChange={handleSearchChange}
        sx={{ mb: 2 }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {searchTerm && (
                <IconButton
                  aria-label="clear search"
                  onClick={handleClearSearch}
                  edge="end"
                >
                  <ClearIcon />
                </IconButton>
              )}
            </InputAdornment>
          ),
        }}
      />
      <Box 
        ref={containerRef}
        sx={{ 
          overflow: 'auto', 
          maxHeight: 'calc(100vh - 250px)',
          border: '1px solid',
          borderColor: 'divider',
          borderRadius: 1,
          '& > :not(:last-child)': {
            borderBottom: '1px solid',
            borderColor: 'divider',
          },
        }}
      >
        {filteredFacilities.map((facility, index) => (
          <div key={facility.id} data-index={index}>
            {renderFacilityRow(facility, index)}
          </div>
        ))}
      </Box>
    </Container>
  );
};

export default React.memo(FacilitiesContent);