const BASE_URL = process.env.REACT_APP_BACKEND_URL;

export const fetchPurchases = async (token, searchTerm = "", startDate = "", endDate = "") => {
  try {
    const url = new URL(`${BASE_URL}/api/v1/purchases`);
    if (searchTerm) url.searchParams.append("search", searchTerm);
    if (startDate) url.searchParams.append("startDate", startDate);
    if (endDate) url.searchParams.append("endDate", endDate);

    const response = await fetch(url.toString(), {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return await response.json();
  } catch (error) {
    console.error("Error fetching purchases:", error);
    throw error;
  }
};

export const fetchPurchaseById = async (purchaseId, token) => {
  try {
    const response = await fetch(`${BASE_URL}/api/v1/purchases/${purchaseId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return await response.json();
  } catch (error) {
    console.error(`Error fetching purchase with ID ${purchaseId}:`, error);
    throw error;
  }
};

export const fetchAggregateSpendByLocalityOverTime = async (token, startDate, endDate, facilityIds, reportType) => {
  try {
    const queryParams = new URLSearchParams({
      start_date: startDate.format('YYYY-MM-DD'),
      end_date: endDate.format('YYYY-MM-DD'),
      facility_ids: facilityIds.join(','),
      report_type: reportType
    });

    const response = await fetch(`${BASE_URL}/api/v1/purchases/aggregate_spend_by_locality_over_time?${queryParams}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return await response.json();
  } catch (error) {
    console.error("Error fetching aggregate spend by locality over time:", error);
    throw error;
  }
};

export const fetchFacilitiesBySpend = async (token, startDate, endDate) => {
  try {
    let url = `${BASE_URL}/api/v1/purchases/facilities_by_spend`;
    if (startDate && endDate) {
      url += `?start_date=${startDate}&end_date=${endDate}`;
    }
    const response = await fetch(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return await response.json();
  } catch (error) {
    console.error("Error fetching facilities by spend:", error);
    throw error;
  }
};

export const fetchFacilitiesByCategory = async (token, category, sortBy, startDate, endDate) => {
  try {
    let url = `${BASE_URL}/api/v1/purchases/facilities_by_category?category=${category}&sort_by=${sortBy}`;
    if (startDate && endDate) {
      url += `&start_date=${startDate}&end_date=${endDate}`;
    }
    const response = await fetch(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return await response.json();
  } catch (error) {
    console.error(`Error fetching facilities by ${category}:`, error);
    throw error;
  }
};

export const generateReport = async (token, startDate, endDate, facilityIds, reportType) => {
  try {
    const queryParams = new URLSearchParams({
      start_date: startDate.format('YYYY-MM-DD'),
      end_date: endDate.format('YYYY-MM-DD'),
      facility_ids: facilityIds.join(','),
      report_type: reportType
    });

    const response = await fetch(`${BASE_URL}/api/v1/purchases/generate_report?${queryParams}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = `spending_report_${startDate.format('YYYY-MM-DD')}_${endDate.format('YYYY-MM-DD')}.csv`;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error("Error generating report:", error);
    throw error;
  }
};