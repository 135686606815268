import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Navigate } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import { jwtDecode } from "jwt-decode";

const RequireAdminRole = ({ children }) => {
    const { getAccessTokenSilently, isAuthenticated, isLoading } = useAuth0();
    const [hasAdminRole, setHasAdminRole] = React.useState(false);
    const [isRoleChecked, setIsRoleChecked] = React.useState(false);

    React.useEffect(() => {
        const checkAdminRole = async () => {
            if (isAuthenticated) {
                try {
                    const token = await getAccessTokenSilently();
                    const decodedToken = jwtDecode(token);
                    const userRoles = decodedToken.user_role || [];

                    if (userRoles.includes("admin") || userRoles.includes("owner")) {
                        setHasAdminRole(true);
                    }
                    setIsRoleChecked(true);
                } catch (error) {
                    console.error("Error Decoding token:", error);
                    setIsRoleChecked(true);
                }
            } else {
                setIsRoleChecked(true);
            }
        };

        checkAdminRole();
    }, [isAuthenticated, getAccessTokenSilently]);

    if (isLoading || !isRoleChecked) {
        return <CircularProgress />;
    }

    if (!isAuthenticated || !hasAdminRole) {
        return <Navigate to="/dashboard" replace />;
    }

    return children;
};

export default RequireAdminRole;
