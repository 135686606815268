import React, { useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  TextField,
  IconButton,
  Tooltip,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
} from '@mui/material';
import {
  Search,
  VisibilityOutlined,
  EditOutlined,
  DeleteOutlined,
  ExpandMore,
  ExpandLess,
  Business,
  School
} from '@mui/icons-material';
import FacilityItem from './FacilityItem';

export default function AssignedFacilitiesPermissions({ detailedUser }) {
  const [searchQuery, setSearchQuery] = useState('');

  const filteredFacilities = detailedUser.facility_memberships.filter(
    facility => facility.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <Card>
      <CardHeader 
        title={`Assigned Facilities for ${detailedUser.first_name} ${detailedUser.last_name}`}
        subheader={`Email: ${detailedUser.email}`}
      />
      <CardContent>
        <Box mb={2}>
          <TextField
            fullWidth
            placeholder="Search by facility name"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            InputProps={{
              startAdornment: <Search />,
            }}
          />
        </Box>

        <List>
          {filteredFacilities.map((facility) => (
            <FacilityItem
              key={facility.id}
              facility={facility}
              level={0}
              isInformational={true}
            />
          ))}
        </List>
      </CardContent>
    </Card>
  );
}