import React, { memo, useState, useCallback, useRef, useEffect } from "react";
import { Checkbox, FormControlLabel, Divider } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { VariableSizeList as List } from "react-window";

const FacilityCheckbox = memo(({ facility, handleFacilityChange, style }) => (
  <FormControlLabel
    control={
      <Checkbox
        checked={facility.checked}
        onChange={() => handleFacilityChange(facility.id)}
        onClick={(e) => e.stopPropagation()}
        id={`facility-checkbox-${facility.id}`}
        name={`facility-${facility.id}`}
      />
    }
    label={`${facility.name} (${facility.ft_name})`}
    key={facility.id}
    style={style}
  />
));

const FacilityList = ({ facilities, handleFacilityChange, expandedFacilities, setExpandedFacilities, searchTerm }) => {
  const listRef = useRef(null);

  const getItemSize = useCallback(
    (index) => {
      const facility = facilities[index];
      const numChildren = facility.children.length;
      return expandedFacilities.includes(facility.id) ? 60 + numChildren * 40 : 60;
    },
    [expandedFacilities, facilities]
  );

  const handleToggle = useCallback((facilityId) => {
    setExpandedFacilities(prev => 
      prev.includes(facilityId) 
        ? prev.filter(id => id !== facilityId)
        : [...prev, facilityId]
    );
  }, [setExpandedFacilities]);

  useEffect(() => {
    if (listRef.current) {
      listRef.current.resetAfterIndex(0, true);
    }
  }, [expandedFacilities, facilities]);

  useEffect(() => {
    if (searchTerm) {
      const newExpandedFacilities = facilities.reduce((acc, facility) => {
        const facilityMatches = facility.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                                facility.ft_name.toLowerCase().includes(searchTerm.toLowerCase());
        const childMatches = facility.children.some(child => 
          child.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          child.ft_name.toLowerCase().includes(searchTerm.toLowerCase())
        );
  
        // Only expand if a child matches, not if the parent matches
        if (childMatches && !facilityMatches) {
          return [...acc, facility.id];
        }
        return acc;
      }, []);
  
      setExpandedFacilities(prev => [...new Set([...prev, ...newExpandedFacilities])]);
    }
  }, [searchTerm, facilities]);

  const renderItem = useCallback(
    ({ index, style }) => {
      const facility = facilities[index];
      const isExpanded = expandedFacilities.includes(facility.id);

      return (
        <div
          style={{ ...style, display: "flex", flexDirection: "column" }}
          key={facility.id}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "10px 0",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
              onClick={() => handleToggle(facility.id)}
            >
              <FacilityCheckbox
                facility={facility}
                handleFacilityChange={handleFacilityChange}
                style={{ flex: 1 }}
              />
              {facility.children.length > 0 && (
                <ExpandMoreIcon
                  style={{
                    cursor: "pointer",
                    transform: isExpanded ? "rotate(180deg)" : "rotate(0deg)",
                    transition: "transform 0.3s",
                    marginRight: "20px",
                  }}
                />
              )}
            </div>
            <div
              style={{
                maxHeight: isExpanded
                  ? `${facility.children.length * 40}px`
                  : "0",
                overflow: "hidden",
                transition: "max-height 0.3s ease-in-out",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {facility.children.map((childFacility) => (
                <FacilityCheckbox
                  key={childFacility.id}
                  facility={childFacility}
                  handleFacilityChange={handleFacilityChange}
                  style={{ marginLeft: "20px", height: "40px" }}
                />
              ))}
            </div>
          </div>
          <Divider />
        </div>
      );
    },
    [expandedFacilities, facilities, handleFacilityChange, handleToggle]
  );

  return (
    <List
      height={250}
      itemCount={facilities.length}
      itemSize={getItemSize}
      width="100%"
      ref={listRef}
      style={{ overflowX: "hidden" }}
    >
      {renderItem}
    </List>
  );
};

export { FacilityList };