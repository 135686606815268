import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Subheader from "./BaseComponents/Subheader";
import DataDisplay from "./AuthenticatedHomePageContents/DataDisplay";
import GenerateReport from "./AuthenticatedHomePageContents/GenerateReport";
import PastUploads from "./AuthenticatedHomePageContents/PastUploads";
import PurchaseHistory from "./AuthenticatedHomePageContents/PurchaseHistory";
import ChildComponentLayout from "./BaseComponents/ChildComponentLayout";

const tabDetails = [
  { label: "Dashboard", path: "/dashboard", component: DataDisplay },
  {
    label: "Generate Report",
    path: "/dashboard/report",
    component: GenerateReport,
  },
  { label: "Past Uploads", path: "/dashboard/uploads", component: PastUploads },
  {
    label: "Purchase History",
    path: "/dashboard/purchases",
    component: PurchaseHistory,
  },
];

const AuthenticatedHomePageContent = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleTabChange = (path) => {
    navigate(path);
  };

  const getCurrentComponent = () => {
    const currentTabDetail = tabDetails.find((tab) => tab.path === location.pathname);
    return currentTabDetail && currentTabDetail.component
      ? React.createElement(currentTabDetail.component)
      : null;
  };

  return (
    <div>
      <Subheader tabDetails={tabDetails} onTabChange={handleTabChange} currentPath={location.pathname} />
      <ChildComponentLayout>{getCurrentComponent()}</ChildComponentLayout>
    </div>
  );
};

export default AuthenticatedHomePageContent;
