import React, { useState, useEffect, useCallback } from "react";
import {
  Button,
  Paper,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  Typography,
  Tooltip,
  TableSortLabel,
} from "@mui/material";
import DownloadIcon from '@mui/icons-material/Download';
import dayjs from "dayjs";
import { useAuth0 } from "@auth0/auth0-react";
import { fetchUploads, downloadUpload } from "../../utils/api/uploadsApi";
import { useSnackbar } from "../BaseComponents/GlobalErrorSnackbar";
import SearchInputWithDates from "../BaseComponents/SearchInputWithDates";
import advancedFormat from "dayjs/plugin/advancedFormat";

dayjs.extend(advancedFormat);

const PastUploads = () => {
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sortDirection, setSortDirection] = useState('desc');
  const { getAccessTokenSilently } = useAuth0();
  const { handleError } = useSnackbar();

  const fetchFiles = useCallback(
    async (searchTerm, startDate, endDate) => {
      try {
        const token = await getAccessTokenSilently();
        const data = await fetchUploads(token, searchTerm, startDate, endDate);
        const formattedData = data.map((file) => ({
          ...file,
          created_at: dayjs(file.created_at).format("MMMM DD, YYYY h:mm A"),
          created_at_raw: file.created_at, // Keep the raw date for sorting
        }));
        setFiles(formattedData);
      } catch (error) {
        setError(error)
        handleError(error);
      } finally {
        setLoading(false);
      }
    },
    [getAccessTokenSilently]
  );

  const handleDownload = useCallback(async (fileId) => {
    try {
      const token = await getAccessTokenSilently();
      const downloadUrl = await downloadUpload(fileId, token);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = downloadUrl;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (error) {
      handleError(error);
    }
  }, [getAccessTokenSilently, handleError]);

  const handleSort = () => {
    const sortedFiles = [...files].sort((a, b) => {
      if (sortDirection === 'asc') {
        return new Date(a.created_at_raw) - new Date(b.created_at_raw);
      } else {
        return new Date(b.created_at_raw) - new Date(a.created_at_raw);
      }
    });
    setFiles(sortedFiles);
    setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
  };

  useEffect(() => {
    fetchFiles(
      "",
      dayjs().subtract(1, "month").format("YYYY-MM-DD"),
      dayjs().format("YYYY-MM-DD")
    );
  }, [fetchFiles]);

  if (loading) {
    return (
      <Container maxWidth="lg" sx={{ mt: 4, display: "flex", justifyContent: "center", alignItems: "center" }}>
        <CircularProgress />
      </Container>
    );
  }

  if (error) {
    return (
      <Container maxWidth="lg" sx={{ mt: 4, display: "flex", justifyContent: "center", alignItems: "center" }}>
        <Typography variant="h6" color="error">
          Error loading uploads.
        </Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>
      <Typography variant="h4" align="center" sx={{ mb: 4, fontWeight: "bold" }}>
        Past Uploads
      </Typography>
      <SearchInputWithDates
        onSearch={fetchFiles}
        placeholder="Search by file name"
      />
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant="subtitle1" fontWeight="bold">
                  File Name
                </Typography>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={true}
                  direction={sortDirection}
                  onClick={handleSort}
                >
                  <Typography variant="subtitle1" fontWeight="bold">
                    Date Uploaded
                  </Typography>
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle1" fontWeight="bold">
                  Download
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {files.map((file) => (
              <TableRow
                key={file.id}
                sx={{
                  "&:nth-of-type(odd)": { backgroundColor: "action.hover" },
                }}
              >
                <TableCell>
                  <Tooltip title={file.s3_url} arrow>
                    <span>{file.original_filename || 'N/A'}</span>
                  </Tooltip>
                </TableCell>
                <TableCell>{file.created_at}</TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<DownloadIcon />}
                    onClick={() => handleDownload(file.id)}
                  >
                    Download
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default PastUploads;