import React, { useCallback } from 'react';
import { ListItem, ListItemText } from '@mui/material';
import { FixedSizeList } from 'react-window';

const VirtualizedFacilitySelect = ({ facilities, selectedFacility, handleFacilityChange }) => {
  const renderRow = useCallback(
    ({ index, style }) => {
      const facility = facilities[index];
      return (
        <ListItem
          style={style}
          key={facility.id}
          component="div"
          disablePadding
          selected={selectedFacility === facility.id}
          onClick={() => handleFacilityChange(facility.id)}
          sx={{
            pl: facility.level === 1 ? 4 : 2,
            fontWeight: facility.level === 0 ? 'bold' : 'normal',
            '&:hover': {
              backgroundColor: 'action.hover',
              cursor: 'pointer',
            },
            transition: 'background-color 0.2s',
          }}
        >
          <ListItemText
            primary={facility.level === 1 ? `${facility.parentName} - ${facility.name}` : facility.name}
            primaryTypographyProps={{
              fontWeight: facility.level === 0 ? 'bold' : 'normal',
            }}
          />
        </ListItem>
      );
    },
    [facilities, selectedFacility, handleFacilityChange]
  );

  return (
    <FixedSizeList
      height={300}
      width="100%"
      itemSize={48}
      itemCount={facilities.length}
      overscanCount={5}
    >
      {renderRow}
    </FixedSizeList>
  );
};

export default VirtualizedFacilitySelect;