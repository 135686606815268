import React, { useRef, useEffect } from "react";
import * as d3 from "d3";

const DonutChart = ({ data, width = 360, height = 360, colors, showMiddleText = true }) => {
  const ref = useRef();

  useEffect(() => {
    d3.select(ref.current).selectAll("*").remove();

    const radius = Math.min(width, height) / 2;
    const donutWidth = 75;

    const svg = d3
      .select(ref.current)
      .attr("width", width)
      .attr("height", height)
      .append("g")
      .attr("transform", `translate(${width / 2}, ${height / 2})`);

    const arc = d3
      .arc()
      .innerRadius(radius - donutWidth)
      .outerRadius(radius);

    const pie = d3
      .pie()
      .value((d) => d.value)
      .sort(null);

    const total = d3.sum(data, (d) => d.value);

    const sliceGroups = svg
      .selectAll("g.slice")
      .data(pie(data))
      .enter()
      .append("g")
      .attr("class", "slice");

    sliceGroups
      .append("path")
      .attr("d", arc)
      .attr("fill", (d) => colors[d.data.category])
      .attr("stroke", "white")
      .style("stroke-width", "2px");

    sliceGroups
      .append("text")
      .attr("class", "percentage")
      .attr("transform", (d) => `translate(${arc.centroid(d)})`)
      .attr("dy", ".35em")
      .attr("text-anchor", "middle")
      .style("font-size", "12px")
      .style("font-weight", "bold")
      .style("fill", "#fff")
      .style("pointer-events", "none")
      .text((d) => {
        const percentage = Math.round((d.value / total) * 100);
        return percentage > 0 ? `${percentage}%` : '';
      });

    if (showMiddleText) {
      svg
        .append("text")
        .attr("text-anchor", "middle")
        .attr("dy", ".35em")
        .style("font-size", "20px")
        .style("font-weight", "bold")
        .text(`$${Math.round(total)}k`);
    }

    const tooltip = d3
      .select("body")
      .append("div")
      .attr("class", "tooltip")
      .style("position", "absolute")
      .style("padding", "6px")
      .style("background", "rgba(0,0,0,0.6)")
      .style("color", "#fff")
      .style("border-radius", "4px")
      .style("pointer-events", "none")
      .style("opacity", 0);

    sliceGroups
      .on("mouseover", (event, d) => {
        tooltip.style("opacity", 1);
        d3.select(event.currentTarget).select("path").attr("opacity", 0.8);
      })
      .on("mousemove", (event, d) => {
        tooltip
          .html(
            `Category: ${d.data.category}<br>Value: $${Math.round(d.data.value)
            }k<br>Percentage: ${Math.round((d.data.value / total) * 100)}%`
          )
          .style("left", event.pageX + 10 + "px")
          .style("top", event.pageY - 25 + "px");
      })
      .on("mouseout", (event) => {
        tooltip.style("opacity", 0);
        d3.select(event.currentTarget).select("path").attr("opacity", 1);
      });
  }, [data, width, height, colors, showMiddleText]);

  return (
    <svg
      ref={ref}
      aria-label="Donut chart showing food spend by category"
    ></svg>
  );
};

export default DonutChart;