const BASE_URL = process.env.REACT_APP_BACKEND_URL;

export const fetchUploads = async (
  token,
  searchTerm = "",
  startDate = "",
  endDate = ""
) => {
  try {
    const url = new URL(`${BASE_URL}/api/v1/uploads`);
    if (searchTerm) url.searchParams.append("search", searchTerm);
    if (startDate) url.searchParams.append("startDate", startDate);
    if (endDate) url.searchParams.append("endDate", endDate);

    const response = await fetch(url.toString(), {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      throw new Error(response.error);
    }
    return await response.json();
  } catch (error) {
    console.error("Error fetching uploads:", error);
    error.message = "Error fetching uploads (check console for full error)";
    throw error;
  }
};

export const createUpload = async (file, token, distributorId = null) => {
  try {
    const formData = new FormData();
    formData.append("file", file);
    if (distributorId) {
      formData.append("distributor_id", distributorId);
    }

    const response = await fetch(`${BASE_URL}/api/v1/uploads`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || 'Upload failed');
    }

    return await response.json();
  } catch (error) {
    console.error("Error creating upload:", error);
    throw error;
  }
};

export const fetchUploadById = async (uploadId, token) => {
  try {
    const response = await fetch(`${BASE_URL}/api/v1/uploads/${uploadId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      throw new Error(response.error);
    }
    return await response.json();
  } catch (error) {
    console.error(`Error fetching upload with ID ${uploadId}:`, error);
    error.message = `Error fetching upload ${uploadId} (check console for full error)`;
    throw error;
  }
};

export const deleteUploadById = async (uploadId, token) => {
  try {
    const response = await fetch(`${BASE_URL}/api/v1/uploads/${uploadId}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      throw new Error(response.error);
    }
    return await response.json(); 
  } catch (error) {
    console.error(`Error deleting upload with ID ${uploadId}:`, error);
    error.message = `Error deleting upload ${uploadId} (check console for full error)`;
    throw error;
  }
};

export const downloadUpload = async (fileId, token) => {
  try {
    const response = await fetch(`${BASE_URL}/api/v1/uploads/${fileId}/download`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    
    if (!response.ok) {
      throw new Error('Failed to get download URL');
    }
    
    const data = await response.json();
    return data.download_url;
  } catch (error) {
    console.error('Download error:', error);
    throw error;
  }
};