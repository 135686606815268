const BASE_URL = process.env.REACT_APP_BACKEND_URL;



export const updateFacilityMembershipPermissions = async (
  membershipId,
  permissions,
  token
) => {
  try {
    const response = await fetch(
      `${BASE_URL}/api/v1/facility_memberships/${membershipId}/update_permissions`,
      {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          membership_attributes: {
            permissions,
          },
        }),
      }
    );
    if (!response.ok) {
      throw new Error(`Failed to update permissions: ${response.statusText}`);
    }
    return await response.json();
  } catch (error) {
    console.error(
      `Error updating permissions for facility membership with ID ${membershipId}:`,
      error
    );
    error.message = `Error updating permissions for facility membership ID ${membershipId} (check console for full error)`;
    throw error;
  }
};

export const createFacilityMembership = async (userId, facilityId, permissions, token) => {
  try {
    const response = await fetch(`${BASE_URL}/api/v1/facility_memberships`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        facility_membership: {
          user_id: userId,
          facility_id: facilityId,
          membership_attributes: {
            permissions: permissions,
          },
        },
      }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || 'Failed to create facility membership');
    }

    return await response.json();
  } catch (error) {
    console.error("Error creating facility membership:", error);
    throw error;
  }
};
