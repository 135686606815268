import React, { createContext, useState, useEffect, useContext, useRef } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { fetchFacilities } from "../utils/api/facilitiesApi";
import { useSnackbar } from "../components/BaseComponents/GlobalErrorSnackbar";

const FacilitiesContext = createContext();

export const FacilitiesProvider = ({ children }) => {
  const [facilities, setFacilities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  const { handleError } = useSnackbar();
  const facilitiesLoaded = useRef(false);

  useEffect(() => {
    const loadFacilities = async () => {
      try {
        const token = await getAccessTokenSilently();
        const data = await fetchFacilities(token);
        setFacilities(data);
      } catch (error) {
        setError(error);
        handleError(error);
      } finally {
        setLoading(false);
      }
    };

    if (isAuthenticated && !facilitiesLoaded.current) {
      loadFacilities();
      facilitiesLoaded.current = true;
    } else {
      setLoading(false);
    }
  }, [getAccessTokenSilently, handleError, isAuthenticated]);

  return (
    <FacilitiesContext.Provider value={{ facilities, setFacilities, loading, error }}>
      {children}
    </FacilitiesContext.Provider>
  );
};

export const useFacilities = () => useContext(FacilitiesContext);