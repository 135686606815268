import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Navigate } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress'; 


const RequireAuthentication = ({ children }) => {
  const { isAuthenticated, isLoading } = useAuth0();
  if (isLoading) {
    return <CircularProgress />;
  }
  return isAuthenticated ? children : <Navigate to="/" replace />;
};

export default RequireAuthentication;
