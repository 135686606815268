import React, { createContext, useContext, useState, useEffect, useCallback, useMemo } from 'react';
import { Alert, Box } from '@mui/material';

const SnackbarContext = createContext();

export const useSnackbar = () => useContext(SnackbarContext);

let nextSnackbarId = 0;

export function GlobalErrorSnackbar({ children, autoHideDuration = 6000 }) {
  const [snackbarMessages, setSnackbarMessages] = useState([]);

  const showSnackbar = useCallback((message, severity = 'error') => {
    const newSnackbar = { 
      id: nextSnackbarId++, 
      message, 
      severity,
      createdAt: new Date().getTime()
    };
    setSnackbarMessages((prev) => [...prev, newSnackbar]);
  }, []);

  const handleSnackbarClose = useCallback((id, reason) => {
    if (reason !== 'clickaway') {
      setSnackbarMessages((prev) => prev.filter((snackbar) => snackbar.id !== id));
    }
  }, []);

  const handleError = useCallback((error) => {
    const message = error.response?.data?.message || error.message;
    showSnackbar(message, 'error');
  }, [showSnackbar]);

  const handleWarning = useCallback((error) => {
    const message = error.response?.data?.message || error.message;
    showSnackbar(message, 'warning');
  }, [showSnackbar]);

  const handleSuccess = useCallback((message) => {
    showSnackbar(message, 'success');
  }, [showSnackbar]);

  useEffect(() => {
    const timer = setInterval(() => {
      const now = new Date().getTime();
      setSnackbarMessages((prev) => 
        prev.filter((snackbar) => now - snackbar.createdAt < autoHideDuration)
      );
    }, 1000);

    return () => clearInterval(timer);
  }, [autoHideDuration]);

  const contextValue = useMemo(() => ({
    showSnackbar,
    handleError,
    handleWarning,
    handleSuccess
  }), [showSnackbar, handleError, handleWarning, handleSuccess]);

  return (
    <SnackbarContext.Provider value={contextValue}>
      {children}
      <Box
        style={{
          position: 'fixed',
          bottom: '8px',
          right: '8px',
          display: 'flex',
          flexDirection: 'column-reverse',
          alignItems: 'flex-end',
          gap: '8px',
        }}
      >
        {snackbarMessages.map((snackbar) => (
          <Box
            key={snackbar.id}
            style={{ pointerEvents: 'auto' }}
          >
            <Alert
              onClose={(reason) => handleSnackbarClose(snackbar.id, reason)}
              severity={snackbar.severity}
              variant="filled"
              style={{
                minWidth: '30vw',
                maxWidth: '30vw'
              }}
            >
              {snackbar.message}
            </Alert>
          </Box>
        ))}
      </Box>
    </SnackbarContext.Provider>
  );
}