import React, { useState, useEffect, useCallback } from "react";

import {
  TextField,
  Button,
  Paper,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  Typography,
  Box,
} from "@mui/material";
import { useSnackbar } from "../BaseComponents/GlobalErrorSnackbar";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useAuth0 } from "@auth0/auth0-react";
import { fetchPurchases } from "../../utils/api/purchasesApi"; // Update the path as needed

const PurchaseHistory = () => {
  const [purchases, setPurchases] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [startDate, setStartDate] = useState(dayjs().subtract(1, "month"));
  const [endDate, setEndDate] = useState(dayjs());
  const { getAccessTokenSilently } = useAuth0();
  const { handleError } = useSnackbar();

  const fetchFilteredPurchases = useCallback(async () => {
    try {
      setLoading(true);
      const token = await getAccessTokenSilently();
      const data = await fetchPurchases(token, searchTerm, startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'));
      setPurchases(data);
    } catch (error) {
      setError(error);
      handleError(error);
    } finally {
      setLoading(false);
    }
  }, [getAccessTokenSilently, searchTerm, startDate, endDate]);

  useEffect(() => {
    fetchFilteredPurchases();
  }, []); // Only run on mount

  const handleSearch = () => {
    fetchFilteredPurchases();
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  if (loading) {
    return (
      <Container maxWidth="lg" sx={{ mt: 4, display: "flex", justifyContent: "center", alignItems: "center" }}>
        <CircularProgress />
      </Container>
    );
  }

  if (error) {
    return (
      <Container maxWidth="lg" sx={{ mt: 4, display: "flex", justifyContent: "center", alignItems: "center" }}>
        <Typography variant="h4" align="center" sx={{ mb: 4, fontWeight: "bold" }}>
          Error loading purchases.
        </Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>
      <Typography
        variant="h4"
        align="center"
        sx={{ mb: 4, fontWeight: "bold" }}
      >
        Purchase History
      </Typography>
      <Box
        sx={{
          display: "flex",
          gap: 2,
          flexWrap: "wrap",
          alignItems: "center",
          mb: 2,
        }}
      >
        <TextField
          fullWidth
          label="Search purchases"
          variant="outlined"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          onKeyPress={handleKeyPress}
          sx={{ flexGrow: 1, maxWidth: 300 }}
        />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Start Date"
            value={startDate}
            onChange={setStartDate}
            textField={(params) => <TextField {...params} />}
          />
          <DatePicker
            label="End Date"
            value={endDate}
            onChange={setEndDate}
            textField={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
        <Button
          variant="contained"
          onClick={handleSearch}
          sx={{ height: "56px" }}
        >
          Search
        </Button>
      </Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell><Typography variant="subtitle1" fontWeight="bold">Product</Typography></TableCell>
              <TableCell align="right"><Typography variant="subtitle1" fontWeight="bold">Value</Typography></TableCell>
              <TableCell align="right"><Typography variant="subtitle1" fontWeight="bold">Quantity</Typography></TableCell>
              <TableCell><Typography variant="subtitle1" fontWeight="bold">Producer</Typography></TableCell>
              <TableCell><Typography variant="subtitle1" fontWeight="bold">Date Purchased</Typography></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {purchases.map((purchase) => (
              <TableRow
                key={purchase.id}
                sx={{
                  "&:nth-of-type(odd)": { backgroundColor: "action.hover" },
                }}
              >
                <TableCell>{purchase.product_name}</TableCell>
                <TableCell align="right">
                  {purchase.dollar_value !== null
                    ? `$ ${parseFloat(purchase.dollar_value).toFixed(2)}`
                    : "Data Not Available"}
                </TableCell>
                <TableCell align="right">{`${purchase.quantity} ${purchase.friendly_unit}`}</TableCell>
                <TableCell>{purchase.supplier_name}</TableCell>
                <TableCell>{purchase.middle_date}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default PurchaseHistory;