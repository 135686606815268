import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  IconButton,
  Typography,
  styled,
} from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useAuth0 } from "@auth0/auth0-react";
import { fetchUserById, updateUserPermissions } from "../../utils/api/usersApi";
import { useSnackbar } from "../BaseComponents/GlobalErrorSnackbar";
import AssignedFacilitiesPermissions from "./AssignedFacilitiesPermissions";
import AssignNewFacilities from "./AssignNewFacilities";

const BreadcrumbItem = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  padding: theme.spacing(0.5, 1),
  backgroundColor: theme.palette.grey[200],
  borderRadius: theme.shape.borderRadius,
  '&:not(:last-child)': {
    marginRight: theme.spacing(1),
    '&::after': {
      content: '""',
      width: 0,
      height: 0,
      borderTop: '10px solid transparent',
      borderBottom: '10px solid transparent',
      borderLeft: `10px solid ${theme.palette.grey[200]}`,
      position: 'absolute',
      right: -10,
      top: '50%',
      transform: 'translateY(-50%)',
    },
  },
  position: 'relative',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: theme.palette.grey[300],
    '&::after': {
      borderLeftColor: theme.palette.grey[300],
    },
  },
}));

export default function UserPermissionManagement({ user, open, onClose }) {
  const [detailedUser, setDetailedUser] = useState(null);
  const { getAccessTokenSilently } = useAuth0();
  const { handleError } = useSnackbar();
  const [showAssignNewFacilities, setShowAssignNewFacilities] = useState(false);

  useEffect(() => {
    const loadUserDetails = async () => {
      try {
        const token = await getAccessTokenSilently();
        const detailedUser = await fetchUserById(user.id, token);
        setDetailedUser(detailedUser);
      } catch (error) {
        handleError(error);
      }
    };

    if (open) {
      loadUserDetails();
    }
  }, [user, open, getAccessTokenSilently, handleError]);

  const handleSave = async () => {
    try {
      const token = await getAccessTokenSilently();
      const updatedFacilityMemberships = detailedUser.facility_memberships.map(
        (fm) => ({
          facility_id: fm.facility_id,
          permissions: fm.facility_permissions,
        })
      );
      await updateUserPermissions(user.id, updatedFacilityMemberships, token);
      onClose();
    } catch (error) {
      handleError(error);
    }
  };

  const handleAssignNewFacilities = async (newFacilities) => {
    try {
      const token = await getAccessTokenSilently();
      // Here we would call an API endpoint to assign new facilities
      // This endpoint should handle creating new facility memberships
      // await assignNewFacilities(user.id, newFacilities, token);

      // After successful assignment, reload user details
      const updatedUser = await fetchUserById(user.id, token);
      setDetailedUser(updatedUser);
      setShowAssignNewFacilities(false);
    } catch (error) {
      handleError(error);
    }
  };

  const handleBreadcrumbClick = () => {
    setShowAssignNewFacilities(false);
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {showAssignNewFacilities && (
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => setShowAssignNewFacilities(false)}
              aria-label="back"
              sx={{ mr: 2 }}
            >
              <ArrowBackIcon />
            </IconButton>
          )}
          <Typography variant="h6">
            Manage Permissions for {user.first_name} {user.last_name}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <BreadcrumbItem onClick={handleBreadcrumbClick}>
            <Typography variant="body2">Assigned Facilities</Typography>
          </BreadcrumbItem>
          {showAssignNewFacilities && (
            <BreadcrumbItem>
              <Typography variant="body2">Assign New Facilities</Typography>
            </BreadcrumbItem>
          )}
        </Box>
      </DialogTitle>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column', height: '60vh' }}>
        {showAssignNewFacilities ? (
          <Box flexGrow={1} display="flex" flexDirection="column" overflow="hidden">
            <AssignNewFacilities
              detailedUser={detailedUser}
              onAssign={handleAssignNewFacilities}
            />
          </Box>
        ) : (
          <Box display="flex" flexDirection="column" height="100%">
            {detailedUser && (
              <>
                <Box flexGrow={1} overflow="auto" mb={2}>
                  <AssignedFacilitiesPermissions
                    detailedUser={detailedUser}
                    setDetailedUser={setDetailedUser}
                  />
                </Box>
                <Box display="flex" justifyContent="flex-end">
                  <Button
                    onClick={() => setShowAssignNewFacilities(true)}
                    variant="contained"
                    color="primary"
                  >
                    Assign New Facilities
                  </Button>
                </Box>
              </>
            )}
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}