const BASE_URL = process.env.REACT_APP_BACKEND_URL;

export const sendFeedbackMessage = async (message, token) => {
  if (!token) {
    console.error('No token provided to sendFeedbackMessage');
    throw new Error('Authentication token is missing');
  }

  try {
    const url = new URL(`${BASE_URL}/api/v1/feedback`);
    const response = await fetch(url.toString(), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify(message),
    });

    if (!response.ok) {
      throw new Error(`Failed to send feedback: ${response.statusText}`);
    }

    return await response.text();
  } catch (error) {
    console.error('Error sending feedback:', error);
    throw error;
  }
};