const BASE_URL = process.env.REACT_APP_BACKEND_URL;

export const fetchFacilities = async (token) => {
  try {
    const response = await fetch(`${BASE_URL}/api/v1/facilities`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      throw new Error(response.error);
    }
    return await response.json();
  } catch (error) {
    console.error("Error fetching facilities:", error);
    error.message = "Error fetching facilities (check console for full error)";
    throw error;
  }
};

export const fetchFacilityById = async (facilityId, token) => {
  try {
    const response = await fetch(`${BASE_URL}/api/v1/facilities/${facilityId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      throw new Error(response.error);
    }
    return await response.json();
  } catch (error) {
    console.error(`Error fetching facility with ID ${facilityId}:`, error);
    error.message = `Error fetching facility ID ${facilityId} (check console for full error)`;
    throw error;
  }
};

