import React, { useRef, useEffect, useState } from "react";
import { Box, useTheme } from "@mui/material";
import * as d3 from "d3";
import { fetchFacilitiesBySpend, fetchFacilitiesByCategory } from "../../../utils/api/purchasesApi";
import { useAuth0 } from "@auth0/auth0-react";

const TopFacilities = ({ colors, selectedCategory, sortBy }) => {
  const [data, setData] = useState([]);
  const ref = useRef();
  const containerRef = useRef();
  const { getAccessTokenSilently } = useAuth0();
  const theme = useTheme();

  const categoryMapping = {
    'total': 'Total Spend',
    'local': 'Local',
    'regional': 'Regional',
    'not_local': 'Not Local',
    'unknown': 'Unknown',
  };

  const formatToWholeThousands = (value) => {
    return d3.format("$,.0f")(value);
  };

  const formatToPercentage = (value) => {
    return d3.format(".1%")(value);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        let facilitiesData;
        
        if (selectedCategory === "total") {
          facilitiesData = await fetchFacilitiesBySpend(accessToken);
        } else {
          facilitiesData = await fetchFacilitiesByCategory(accessToken, selectedCategory, sortBy);
        }
        
        setData(facilitiesData);
      } catch (error) {
        console.error("Error fetching facilities data:", error);
      }
    };
    fetchData();
  }, [selectedCategory, sortBy]);

  useEffect(() => {
    if (data.length === 0 || !containerRef.current) return;

    const containerWidth = containerRef.current.offsetWidth;
    const containerHeight = containerRef.current.offsetHeight;

    const margin = { top: 40, right: 120, bottom: 100, left: 280 };
    const width = containerWidth - margin.left - margin.right;
    const height = containerHeight - margin.top - margin.bottom;

    const svg = d3.select(ref.current);
    svg.selectAll("*").remove();

    svg.attr("width", containerWidth)
       .attr("height", containerHeight);

    const svgContainer = svg.append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    const baseCategories = ["local", "regional", "not_local", "unknown"];
    
    // Reorder categories to put the selected category first
    const categories = selectedCategory === "total"
      ? baseCategories
      : [selectedCategory, ...baseCategories.filter(cat => cat !== selectedCategory)];

    const stack = d3.stack().keys(categories.map(cat => `${cat}_spend`));
    const stackedData = stack(data);

    const y = d3.scaleBand()
      .domain(data.map(d => d.name))
      .range([0, height])
      .padding(0.1);

    let x;
    if (sortBy === "percentage" && selectedCategory !== "total") {
      x = d3.scaleLinear()
        .domain([0, 1])  // 0% to 100%
        .range([0, width]);
    } else {
      x = d3.scaleLinear()
        .domain([0, d3.max(stackedData[stackedData.length - 1], d => d[1])])
        .range([0, width]);
    }

    // Update color scale to use the new category order
    const color = d3.scaleOrdinal()
      .domain(categories)
      .range(categories.map(cat => colors[categoryMapping[cat]]));

    // Tooltip
    const tooltip = d3.select("body").append("div")
      .attr("class", "tooltip")
      .style("position", "absolute")
      .style("padding", "10px")
      .style("background", "rgba(0,0,0,0.8)")
      .style("color", "#fff")
      .style("border-radius", "5px")
      .style("font-size", "14px")
      .style("pointer-events", "none")
      .style("opacity", 0);

    const mouseover = (event, d) => {
      tooltip.style("opacity", 1);
      d3.select(event.currentTarget).style("opacity", 0.8);
    };

    const mousemove = (event, d) => {
      const category = d3.select(event.currentTarget.parentNode).datum().key;
      const categoryName = category.replace('_spend', '');
      const value = d[1] - d[0];
      const facilityName = d.data.name;
      const totalSpend = d.data.total_spend;
      const percentage = value / totalSpend;

      tooltip
        .html(`
          <strong>Facility:</strong> ${facilityName}<br>
          <strong>Category:</strong> ${categoryMapping[categoryName] || categoryName}<br>
          <strong>Value:</strong> ${formatToWholeThousands(value)}<br>
          <strong>Percentage:</strong> ${formatToPercentage(percentage)}
        `)
        .style("left", (event.pageX + 10) + "px")
        .style("top", (event.pageY - 25) + "px");
    };

    const mouseleave = () => {
      tooltip.style("opacity", 0);
      d3.selectAll("rect").style("opacity", 1);
    };

    const barGroups = svgContainer.append("g")
      .selectAll("g")
      .data(stackedData)
      .enter().append("g")
        .attr("fill", d => color(d.key.replace('_spend', '')));

    barGroups.selectAll("rect")
      .data(d => d)
      .enter().append("rect")
        .attr("y", d => y(d.data.name))
        .attr("x", d => sortBy === "percentage" ? x(d[0] / d.data.total_spend) : x(d[0]))
        .attr("width", d => {
          if (sortBy === "percentage") {
            return x((d[1] - d[0]) / d.data.total_spend) - x(0);
          } else {
            return x(d[1]) - x(d[0]);
          }
        })
        .attr("height", y.bandwidth())
        .on("mouseover", mouseover)
        .on("mousemove", mousemove)
        .on("mouseout", mouseleave);

    // Add percentage labels when in percentage mode
    if (sortBy === "percentage") {
      barGroups.selectAll("text")
        .data(d => d)
        .enter()
        .append("text")
        .attr("x", d => x(d[0] / d.data.total_spend) + (x((d[1] - d[0]) / d.data.total_spend) - x(0)) / 2)
        .attr("y", d => y(d.data.name) + y.bandwidth() / 2)
        .attr("dy", "0.35em")
        .attr("text-anchor", "middle")
        .style("font-size", "10px")
        .style("font-weight", "bold")
        .style("fill", "#fff")
        .text(d => {
          const percentage = (d[1] - d[0]) / d.data.total_spend;
          return percentage > 0.05 ? `${(percentage * 100).toFixed(1)}%` : '';
        });
    }

    // X-axis

    svgContainer.append("g")
      .attr("transform", `translate(0,${height})`)
      .call(d3.axisBottom(x).tickFormat(d => sortBy === "percentage" && selectedCategory !== "total" ? formatToPercentage(d) : formatToWholeThousands(d)))
      .selectAll("text")
        .style("text-anchor", "end")
        .attr("dx", "-.8em")
        .attr("dy", ".15em")
        .attr("transform", "rotate(-45)")
        .style("font-weight", "bold")
        .style("font-size", "14px");

    // Y-axis
    svgContainer.append("g")
      .call(d3.axisLeft(y))
      .selectAll("text")
        .call(wrap, margin.left - 10)
        .style("font-weight", "bold")
        .style("font-size", "14px");

    // X-axis label
    svgContainer.append("text")
      .attr("text-anchor", "middle")
      .attr("x", width / 2)
      .attr("y", height + margin.bottom - 10)
      .text(sortBy === "percentage" && selectedCategory !== "total" ? "Percentage of Total Spend" : "Total Spent ($)")
      .style("font-weight", "bold")
      .style("font-size", "16px");

    // Add total spend values at the end of each bar
    if (sortBy === "dollar") {
      svgContainer.append("g")
        .selectAll("text")
        .data(data)
        .enter()
        .append("text")
          .attr("x", d => x(d.total_spend) + 5)
          .attr("y", d => y(d.name) + y.bandwidth() / 2)
          .attr("dy", ".35em")
          .attr("text-anchor", "start")
          .text(d => formatToWholeThousands(d.total_spend))
          .style("fill", theme.palette.text.primary)
          .style("font-size", "14px");
    }

    // Function to wrap long text
    function wrap(text, width) {
      text.each(function() {
        var text = d3.select(this),
            words = text.text().split(/\s+/).reverse(),
            word,
            line = [],
            lineNumber = 0,
            lineHeight = 1.1, // ems
            y = text.attr("y"),
            dy = parseFloat(text.attr("dy")),
            tspan = text.text(null).append("tspan").attr("x", -10).attr("y", y).attr("dy", dy + "em");
        while (word = words.pop()) {
          line.push(word);
          tspan.text(line.join(" "));
          if (tspan.node().getComputedTextLength() > width) {
            line.pop();
            tspan.text(line.join(" "));
            line = [word];
            tspan = text.append("tspan").attr("x", -10).attr("y", y).attr("dy", ++lineNumber * lineHeight + dy + "em").text(word);
          }
        }
      });
    }
  }, [data, colors, theme, sortBy, selectedCategory]);

  return (
    <Box sx={{ width: '100%', height: '100%', minHeight: 600 }}>
      <Box ref={containerRef} sx={{ width: '100%', height: '100%' }}>
        <svg ref={ref} style={{ width: '100%', height: '100%' }}></svg>
      </Box>
    </Box>
  );
};

export default TopFacilities;