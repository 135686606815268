const BASE_URL = process.env.REACT_APP_BACKEND_URL;

export const fetchSuppliers = async (token) => {
  try {
    const response = await fetch(`${BASE_URL}/api/v1/suppliers`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      throw new Error(response.error);
    }
    return await response.json();
  } catch (error) {
    console.error("Error fetching suppliers:", error);
    error.message = "Error fetching suppliers (check console for full error)";
    throw error;
  }
};

export const fetchSupplierById = async (supplierId, token) => {
  try {
    const response = await fetch(`${BASE_URL}/api/v1/suppliers/${supplierId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      throw new Error(response.error);
    }
    return await response.json();
  } catch (error) {
    console.error(`Error fetching supplier with ID ${supplierId}:`, error);
    error.message = `Error fetching supplier ID ${supplierId} (check console for full error)`;
    throw error;
  }
};


export const searchSuppliers = async (searchQuery, token) => {
  try {
    const response = await fetch(`${BASE_URL}/api/v1/suppliers?search=${encodeURIComponent(searchQuery)}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      throw new Error(response.error);
    }
    return await response.json();
  } catch (error) {
    console.error(`Error searching for suppliers with query "${searchQuery}":`, error);
    error.message = `Query error: "${searchQuery}" (check console for full error)`;
    throw error;
  }
};