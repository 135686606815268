import React, { useRef, useEffect } from "react";
import * as d3 from "d3";

const StackedBarChart = ({ data, width = 600, height = 400, colors, formatToThousands, formatToWholeThousands }) => {
  const ref = useRef();
  const chartRef = useRef(null);

  useEffect(() => {
    if (!data || data.length === 0) return;

    d3.select(ref.current).selectAll("*").remove();

    const margin = { top: 20, right: 40, bottom: 60, left: 60 };
    const innerWidth = width - margin.left - margin.right;
    const innerHeight = height - margin.top - margin.bottom;

    const svg = d3
      .select(ref.current)
      .attr("width", width)
      .attr("height", height)
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    const keys = Object.keys(data[0]).filter((key) => key !== "date");
    const stack = d3.stack().keys(keys);
    const stackedData = stack(data);

    const x = d3
      .scaleBand()
      .domain(data.map((d) => d.date))
      .range([0, innerWidth])
      .padding(0.2);

    const y = d3
      .scaleLinear()
      .domain([0, d3.max(stackedData, (d) => d3.max(d, (d) => d[1]))])
      .range([innerHeight, 0])
      .nice();

    const color = d3.scaleOrdinal().domain(keys).range(Object.values(colors));

    const xAxis = d3.axisBottom(x)
      .tickFormat(d3.timeFormat("%b '%y"))
      .tickValues(x.domain().filter((d, i) => !(i % Math.ceil(data.length / 6))));

    const yAxis = d3
      .axisLeft(y)
      .ticks(5)
      .tickFormat((d) => {
        if (d === 0) return '$0';
        return '$' + d3.format('.0f')(d) + 'k';
      });

    svg
      .append("g")
      .attr("transform", `translate(0,${innerHeight})`)
      .call(xAxis)
      .selectAll("text")
      .style("text-anchor", "end")
      .attr("dx", "-.8em")
      .attr("dy", ".15em")
      .attr("transform", "rotate(-45)")
      .style("font-size", "10px")
      .style("font-weight", "bold");

    svg
      .append("g")
      .call(yAxis)
      .selectAll("text")
      .style("font-size", "12px")
      .style("font-weight", "bold");

    svg
      .append("text")
      .attr("transform", "rotate(-90)")
      .attr("y", 0 - margin.left)
      .attr("x", 0 - innerHeight / 2)
      .attr("dy", "1em")
      .style("text-anchor", "middle")
      .text("Spend (USD)");

    const barGroups = svg
      .append("g")
      .selectAll("g")
      .data(stackedData)
      .enter()
      .append("g")
      .attr("fill", (d) => color(d.key));

    const barSegments = barGroups
      .selectAll("g")
      .data((d) => d)
      .enter()
      .append("g")
      .attr("class", "bar-segment");

    barSegments
      .append("rect")
      .attr("x", (d) => x(d.data.date))
      .attr("y", (d) => y(d[1]))
      .attr("height", (d) => y(d[0]) - y(d[1]))
      .attr("width", x.bandwidth());

    barSegments
      .append("text")
      .attr("x", (d) => x(d.data.date) + x.bandwidth() / 2)
      .attr("y", (d) => y(d[1]) + (y(d[0]) - y(d[1])) / 2)
      .attr("dy", "0.35em")
      .attr("text-anchor", "middle")
      .style("font-size", "10px")
      .style("font-weight", "bold")
      .style("fill", "#fff")
      .style("pointer-events", "none")
      .text((d) => {
        const total = d3.sum(keys, (key) => d.data[key]);
        const value = d[1] - d[0];
        const percentage = (value / total) * 100;
        return percentage >= 5 ? `${Math.round(percentage)}%` : '';
      });

    const tooltip = d3
      .select("body")
      .append("div")
      .attr("class", "tooltip")
      .style("position", "absolute")
      .style("padding", "6px")
      .style("background", "rgba(0,0,0,0.6)")
      .style("color", "#fff")
      .style("border-radius", "4px")
      .style("pointer-events", "none")
      .style("opacity", 0);

    const mouseover = (event, d) => {
      tooltip.style("opacity", 1);
      d3.select(event.currentTarget).select("rect").style("opacity", 0.8);
    };

    const mousemove = (event, d) => {
      const category = d3.select(event.currentTarget.parentNode).datum().key;
      const value = d[1] - d[0];

      const total = d3.sum(keys, key => d.data[key]);
      const percentage = value / total;

      tooltip
        .html(`
          <strong>Category:</strong> ${category}<br>
          <strong>Percentage:</strong> ${d3.format(".1%")(percentage)}
        `)
        .style("left", (event.pageX + 10) + "px")
        .style("top", (event.pageY - 25) + "px");
    };

    const mouseleave = () => {
      tooltip.style("opacity", 0);
      d3.selectAll("rect").style("opacity", 1);
    };

    barSegments
      .on("mouseover", mouseover)
      .on("mousemove", mousemove)
      .on("mouseout", mouseleave);

    svg
      .selectAll(".total-label")
      .data(data)
      .enter()
      .append("text")
      .attr("class", "total-label")
      .attr("x", (d) => x(d.date) + x.bandwidth() / 2)
      .attr("y", (d) => y(d3.sum(keys, (key) => d[key])) - 5)
      .attr("text-anchor", "middle")
      .attr("font-size", "10px")
      .text((d) => '$' + formatToWholeThousands(d3.sum(keys, (key) => d[key]) * 1000));

    chartRef.current = () => {
      svg.selectAll("*").remove();
      tooltip.remove();
    };

    return () => {
      if (chartRef.current) {
        chartRef.current();
      }
    };
  }, [data, width, height, colors, formatToThousands, formatToWholeThousands]);

  return (
    <svg
      ref={ref}
      aria-label="Stacked bar chart showing food spend over time"
    ></svg>
  );
};

export default StackedBarChart;