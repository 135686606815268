import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { sendFeedbackMessage } from "../../../utils/api/feedbackApi";
import { useSnackbar } from "../../BaseComponents/GlobalErrorSnackbar";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  Box,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const FeatureRequestModal = ({ open, onClose, onSubmit, title }) => {
  const { user, getAccessTokenSilently } = useAuth0();
  const { handleError, handleSuccess } = useSnackbar();
  const [featureDescription, setFeatureDescription] = useState("");
  const [featureBenefit, setFeatureBenefit] = useState("");
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = async () => {
    try {
      const token = await getAccessTokenSilently();
      
      if (!token) {
        console.error('Failed to get access token');
        return;
      }

      const feedbackMessage = {
        feedback_type: 'feature_request',
        user_email: user?.email || 'Unknown',
        feature_description: featureDescription,
        feature_benefit: featureBenefit,
        domain: window.location.origin,
      };

      await sendFeedbackMessage(feedbackMessage, token);
      setSubmitted(true);
      handleSuccess("Feature request submitted successfully");
    } catch (error) {
      console.error('Error submitting feature request:', error);
      handleError(new Error("Failed to submit feature request: " + error.message));
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        {submitted ? "Thank You!" : title}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {submitted ? (
          <Typography variant="h6" align="center">
            Thank you for your feedback!
          </Typography>
        ) : (
          <Box
            component="form"
            sx={{ display: "flex", flexDirection: "column", gap: 2 }}
          >
            <TextField
              autoFocus
              margin="dense"
              label="What feature would you like to see?"
              fullWidth
              variant="outlined"
              multiline
              rows={4}
              value={featureDescription}
              onChange={(e) => setFeatureDescription(e.target.value)}
              InputProps={{
                style: { fontSize: 16 },
              }}
              InputLabelProps={{
                style: { fontSize: 16 },
              }}
            />
            <TextField
              margin="dense"
              label="How would this help you?"
              fullWidth
              variant="outlined"
              multiline
              rows={4}
              value={featureBenefit}
              onChange={(e) => setFeatureBenefit(e.target.value)}
              InputProps={{
                style: { fontSize: 16 },
              }}
              InputLabelProps={{
                style: { fontSize: 16 },
              }}
            />
          </Box>
        )}
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center", gap: 2 }}>
        {submitted ? (
          <Button
            onClick={onClose}
            color="primary"
            sx={{
              padding: "10px 24px",
              fontSize: "1rem",
              borderRadius: "50px",
            }}
          >
            Close
          </Button>
        ) : (
          <>
            <Button
              onClick={onClose}
              color="primary"
              sx={{
                padding: "10px 24px",
                fontSize: "1rem",
                borderRadius: "50px",
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleSubmit}
              color="primary"
              sx={{
                padding: "10px 24px",
                fontSize: "1rem",
                borderRadius: "50px",
              }}
            >
              Submit
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default FeatureRequestModal;
