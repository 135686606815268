import { useAuth0 } from "@auth0/auth0-react"; 
import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress"; 
import { GlobalErrorSnackbar } from "./components/BaseComponents/GlobalErrorSnackbar";
import Layout from "./components/BaseComponents/Layout";
import HomePageContent from "./components/HomePageContent";
import FileUploadContent from "./components/FileUploadContent";
import SuppliersContent from "./components/SuppliersContent";
import RequireAuthentication from "./components/RequireAuthentication";
import { RoleProvider } from "./components/RoleProvider";
import RequireAdminRole from "./components/RequireAdminRole";
import AuthenticatedAdminPageContent from "./components/AuthenticatedAdminPageContent";
import FeedbackForm from "./components/AuthenticatedFeedbackContent/FeedbackForm";
import AuthenticatedHomePageContent from "./components/AuthenticatedHomePageContent";
import UserManagementContent from "./components/AuthenticatedAdminPageContents/UserManagementContent";
import { FacilitiesProvider } from "./context/FacilitiesContext";
import ProductsContent from "./components/ProductsContent";

const AuthRedirect = () => {
  const { isAuthenticated, isLoading } = useAuth0();
  const navigate = useNavigate();
  
  useEffect(() => {
    if (isAuthenticated) {
      navigate("/admin");
    }
  }, [navigate]);

  if (isLoading) {
    return <CircularProgress />;
  }

  if (!isAuthenticated) {
  return <HomePageContent />;
  }

  return null // Return null if authenticated to prevent rendering HomePageContent
};

function App() {
  return (
    <GlobalErrorSnackbar>
      <FacilitiesProvider>
        <Router>
          <RoleProvider>
            <Layout>
              <Routes>
                <Route path="/" element={<AuthRedirect />} />
                <Route
                  path="/upload"
                  element={
                    <RequireAuthentication>
                      <FileUploadContent />
                    </RequireAuthentication>
                  }
                />
                <Route
                  path="/products"
                  element={
                    <RequireAuthentication>
                      <ProductsContent />
                    </RequireAuthentication>
                  }
                />
                <Route
                  path="/feedback"
                  element={
                    <RequireAuthentication>
                      <FeedbackForm />
                    </RequireAuthentication>
                  }
                />
                <Route
                  path="/admin/*"
                  element={
                    <RequireAdminRole>
                      <AuthenticatedAdminPageContent />
                    </RequireAdminRole>
                  }
                />
                <Route
                  path="/dashboard/*"
                  element={
                    <RequireAuthentication>
                      <AuthenticatedHomePageContent />
                    </RequireAuthentication>
                  }
                />
              </Routes>
            </Layout>
          </RoleProvider>
        </Router>
      </FacilitiesProvider>
    </GlobalErrorSnackbar>
  );
}

export default App;