import React, { useState } from 'react';

import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
  Collapse,
  IconButton,
} from '@mui/material';
import { 
  ExpandMore, 
  ExpandLess, 
  Business, 
  School,
} from '@mui/icons-material';

const FacilityItem = ({ 
  facility, 
  level, 
  selectedFacilities, 
  onFacilityChange, 
  isInformational = false 
}) => {
  const [open, setOpen] = useState(false);
  const isDistrict = facility.facility_type === 'District';

  const isChecked = !isInformational && isFullyChecked(facility, selectedFacilities);
  const hasCheckedChildren = !isInformational && hasAnyCheckedChildren(facility, selectedFacilities);

  const handleCheckboxChange = (e) => {
    e.stopPropagation();
    if (!isInformational && onFacilityChange) {
      const newCheckedState = !isChecked;
      updateFacilitySelection(facility, newCheckedState, onFacilityChange);
    }
  };

  const handleExpandClick = () => {
    setOpen(!open);
  };

  const hasPartialAssignment = isInformational && isDistrict && facility.children && 
    facility.children.some(child => child.permissions && child.permissions.length > 0) &&
    (!facility.permissions || facility.permissions.length === 0);

  return (
    <>
      <ListItem
        sx={{ 
          pl: level * 4,
          backgroundColor: hasPartialAssignment ? 'rgba(255, 165, 0, 0.1)' : 'inherit'
        }}
      >
        <ListItemIcon onClick={isInformational ? undefined : handleCheckboxChange}>
          {isInformational ? (
            isDistrict ? <Business color="primary" /> : <School />
          ) : (
            <Checkbox
              edge="start"
              checked={isChecked}
              indeterminate={!isChecked && hasCheckedChildren}
            />
          )}
        </ListItemIcon>
        <ListItemText 
          primary={facility.name} 
          secondary={hasPartialAssignment ? "Partial assignment" : ""}
          onClick={handleExpandClick} 
          sx={{ cursor: 'pointer' }} 
        />
        {facility.children && facility.children.length > 0 && (
          <IconButton onClick={handleExpandClick}>
            {open ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        )}
      </ListItem>
      {facility.children && facility.children.length > 0 && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          {facility.children.map((child) => (
            <FacilityItem
              key={child.id}
              facility={child}
              level={level + 1}
              selectedFacilities={selectedFacilities}
              onFacilityChange={onFacilityChange}
              isInformational={isInformational}
            />
          ))}
        </Collapse>
      )}
    </>
  );
};

// Helper functions
function isFullyChecked(facility, selectedFacilities) {
  if (!selectedFacilities) return false;
  if (!facility.children || facility.children.length === 0) {
    return selectedFacilities.includes(facility.id);
  }
  return facility.children.every(child => isFullyChecked(child, selectedFacilities));
}

function hasAnyCheckedChildren(facility, selectedFacilities) {
  if (!selectedFacilities) return false;
  if (!facility.children || facility.children.length === 0) {
    return selectedFacilities.includes(facility.id);
  }
  return facility.children.some(child => 
    isFullyChecked(child, selectedFacilities) || hasAnyCheckedChildren(child, selectedFacilities)
  );
}

function updateFacilitySelection(facility, isChecked, onFacilityChange) {
  onFacilityChange(facility.id, isChecked);
  
  if (facility.children) {
    facility.children.forEach(child => {
      updateFacilitySelection(child, isChecked, onFacilityChange);
    });
  }
}

export default FacilityItem;