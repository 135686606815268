import React, { useState, useEffect, useCallback } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Container,
  CircularProgress,
  Typography,
} from "@mui/material";
import { useSnackbar } from "./BaseComponents/GlobalErrorSnackbar";
import { fetchSuppliers, searchSuppliers } from "../utils/api/suppliersApi";
import SearchInput from "./BaseComponents/SearchInput";

export default function SuppliersContent() {
  const [suppliers, setSuppliers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const { getAccessTokenSilently } = useAuth0();
  const { handleError } = useSnackbar();

   const handleSearch = useCallback(
     async (query) => {
       try {
         const token = await getAccessTokenSilently();
         const data = query
           ? await searchSuppliers(query, token)
           : await fetchSuppliers(token);
         setSuppliers(data);
       } catch (error) {
         handleError(error);
       }
     },
     [getAccessTokenSilently]
   );

  const handleSearchChange = (newSearchQuery) => {
    setSearchQuery(newSearchQuery);
  };

  useEffect(() => {
    const loadSuppliers = async () => {
      try {
        const token = await getAccessTokenSilently();
        const data = searchQuery
          ? await searchSuppliers(searchQuery, token)
          : await fetchSuppliers(token);
        setSuppliers(data);
      } catch (error) {
        setError(error);
        handleError(error);
      } finally {
        setLoading(false);
      }
    };

    loadSuppliers();
  }, [getAccessTokenSilently, searchQuery]);

  if (loading) {
    return (
      <Container maxWidth="lg" sx={{ mt: 4, display: "flex", justifyContent: "center", alignItems: "center" }}>
        <CircularProgress />
      </Container>
    );
  }

  if (error) {
    return (
      <Container maxWidth="lg" sx={{ mt: 4, display: "flex", justifyContent: "center", alignItems: "center" }}>
        <Typography variant="h6" color="error">
          Error loading suppliers.
        </Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>
      <SearchInput
        onSearch={handleSearch}
        onSearchChange={handleSearchChange}
        placeholder="Search suppliers..."
      />

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: "bold" }}>Supplier</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Distributors</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Address</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Products</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Contact Info</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Regional</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Local</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>
                Location Unknown
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {suppliers.map((supplier) => (
              <TableRow
                key={supplier.id}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.04)" },
                }}
              >
                <TableCell component="th" scope="row">
                  {supplier.company_name}
                </TableCell>
                <TableCell>
                  <ul
                    style={{
                      paddingLeft: "20px",
                      margin: "0",
                      listStyleType: "disc",
                    }}
                  >
                    {supplier.distributor_names &&
                    supplier.distributor_names.length > 0 ? (
                      supplier.distributor_names.map((distributor, index) => (
                        <li key={index} style={{ marginBottom: "4px" }}>
                          {distributor}
                        </li>
                      ))
                    ) : (
                      <li style={{ marginBottom: "4px" }}>No Distributors</li>
                    )}
                  </ul>
                </TableCell>
                <TableCell>
                  {supplier.address_line_1}, {supplier.city}, {supplier.state}{" "}
                  {supplier.zip_code}
                </TableCell>
                <TableCell>
                  No Products (or fetch from another API endpoint if available)
                </TableCell>
                <TableCell>
                  {supplier.email || supplier.phone_number || "No contact info"}
                </TableCell>
                <TableCell>{/* Logic to determine regional */}</TableCell>
                <TableCell>{/* Logic to determine local */}</TableCell>
                <TableCell>{/* Logic for unknown location */}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
}
