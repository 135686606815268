import React, { useState, useEffect, useMemo } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Container,
  CircularProgress,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
} from "@mui/material";
import { fetchProducts } from "../utils/api/productsApi";
import SearchInput from "./BaseComponents/SearchInput";

export default function ProductsContent() {
  const [allProducts, setAllProducts] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const loadProducts = async () => {
      try {
        setLoading(true);
        const token = await getAccessTokenSilently();
        const data = await fetchProducts(token);
        setAllProducts(data);
      } catch (error) {
        console.error("Error loading products:", error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    loadProducts();
  }, [getAccessTokenSilently]);

  const categories = useMemo(() => {
    const categorySet = new Set(allProducts.map(product => product.category));
    return ["All", ...Array.from(categorySet)].sort();
  }, [allProducts]);

  const filteredProducts = useMemo(() => {
    return allProducts.filter(product => 
      (selectedCategory === "All" || product.category === selectedCategory) &&
      (product.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
       product.category.toLowerCase().includes(searchQuery.toLowerCase()) ||
       product.supplier?.company_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
       product.supplier?.distributor_names.some(name => 
         name.toLowerCase().includes(searchQuery.toLowerCase())
       ))
    );
  }, [allProducts, searchQuery, selectedCategory]);

  const handleSearch = (query) => {
    setSearchQuery(query);
  };

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  if (loading) {
    return (
      <Container maxWidth="lg" sx={{ mt: 4, display: "flex", justifyContent: "center", alignItems: "center" }}>
        <CircularProgress />
      </Container>
    );
  }

  if (error) {
    return (
      <Container maxWidth="lg" sx={{ mt: 4, display: "flex", justifyContent: "center", alignItems: "center" }}>
        <Typography variant="h6" color="error">
          Error loading products.
        </Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
        <SearchInput
          value={searchQuery}
          onChange={setSearchQuery}
          onSearch={handleSearch}
          placeholder="Search products..."
          sx={{ flexGrow: 1, mr: 2 }}
        />
        <FormControl sx={{ minWidth: 120 }}>
          <InputLabel id="category-select-label">Category</InputLabel>
          <Select
            labelId="category-select-label"
            id="category-select"
            value={selectedCategory}
            label="Category"
            onChange={handleCategoryChange}
          >
            {categories.map((category) => (
              <MenuItem key={category} value={category}>{category}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: "bold" }}>Product Name</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Category</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Distributors</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Supplier</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredProducts.map((product) => (
              <TableRow
                key={product.id}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.04)" },
                }}
              >
                <TableCell component="th" scope="row">
                  {product.name}
                </TableCell>
                <TableCell>{product.category}</TableCell>
                <TableCell>
                  {product.supplier?.distributor_names && product.supplier.distributor_names.length > 0 ? (
                    <ul style={{ margin: 0, paddingInlineStart: '20px' }}>
                      {[...new Set(product.supplier.distributor_names)].map((distributor, index) => (
                        <li key={index}>{distributor}</li>
                      ))}
                    </ul>
                  ) : (
                    'No distributors'
                  )}
                </TableCell>
                <TableCell>{product.supplier?.company_name || 'N/A'}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
}