import React, { useState, useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Paper from "@mui/material/Paper";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

const Subheader = ({ tabDetails, onTabChange, currentPath }) => {
  const location = useLocation();
  const currentTabIndex = tabDetails.findIndex(
    (item) => item.path === location.pathname
  );
  const [currentTab, setCurrentTab] = useState(currentTabIndex);
  const headerRef = useRef(null);

  useEffect(() => {
    setCurrentTab(currentTabIndex);
  }, [currentPath, currentTab]);

  const handleChange = (event, newValue) => {
    setCurrentTab(newValue);
    if (onTabChange) {
      onTabChange(tabDetails[newValue].path);
    }
  };

  return (
    <div
      ref={headerRef}
      style={{ position: "sticky", top: "64px", zIndex: 1100, width: "100%" }}
    >
      <Paper
        elevation={3}
        sx={{
          borderRadius: 10,
          flexShrink: 0,
          margin: "16px auto",
          bgcolor: "#F5F5F5",
          maxWidth: "800px",
          overflow: "hidden",
          zIndex: 1100,
          position: "sticky",
          top: "70px",
        }}
      >
        <Tabs
          value={currentTab}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
          textColor="inherit"
          indicatorColor="primary.main"
          aria-label="secondary tabs example"
          sx={{
            minHeight: "64px",
            width: "100%",
            ".MuiTabs-flexContainer": {
              justifyContent: "space-between",
              width: "100%",
            },
            "& .MuiTab-root": {
              fontSize: "1.1rem",
              padding: "20px 24px",
              minHeight: "64px",
              whiteSpace: "nowrap",
              textTransform: "none",
              flex: "1 1 auto",
              "&:hover": {
                opacity: 0.9,
                borderRadius: 10,
                bgcolor: "action.hover",
              },
            },
            "& .Mui-selected": {
              color: "white",
              backgroundColor: "primary.main",
              borderRadius: 10,
              "&:hover": {
                bgcolor: "primary.light",
                color: "white",
              },
            },
          }}
        >
          {tabDetails.map((detail, index) => (
            <Tab key={index} label={detail.label} />
          ))}
        </Tabs>
      </Paper>
    </div>
  );
};

export default Subheader;
