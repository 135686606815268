import React, { useState } from "react";
import { Button, Box } from "@mui/material";
import BugReportModal from "./FeedbackFormModals/BugReportModal";
import FeatureRequestModal from "./FeedbackFormModals/FeatureRequestModal";
import ContactUsModal from "./FeedbackFormModals/ContactUsModal";

const FeedbackForm = () => {
  const [feedbackType, setFeedbackType] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");

  const handleFeedbackTypeChange = (type, title) => {
    setFeedbackType(type);
    setModalTitle(title);
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setFeedbackType("");
  };

  const handleSlackSubmit = async (data, type) => {
    // This function is not being used, so we can remove it
    // If you want to keep it for future use, rename it to handleFeedbackSubmit
  };

  // Remove these functions as they're not being used
  // const handleGitHubSubmit = (data) => {
  //   handleSlackSubmit(data, 'GitHub Issue');
  // };

  // const handleEmailSubmit = (data) => {
  //   handleSlackSubmit(data, 'Contact Form');
  // };

  return (
    <Box
      sx={{
        flexGrow: 1,
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start", // Top justify the buttons
        alignItems: "center",
        pt: 4, // Add some padding to the top
      }}
    >
      {!modalOpen ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "50%", // Set the width of the container to 50% of the screen
            gap: 2,
          }}
        >
          <Button
            variant="contained"
            onClick={() => handleFeedbackTypeChange("Bug Report", "Submit a Bug Report")}
            sx={{
              width: "100%", // The button should take up the full width of the container
              height: "12.5vh", // Adjust the height to be smaller
              fontSize: "1.5rem",
              flexDirection: "column",
              alignItems: "center",
              borderRadius: 10,
              padding: "20px 24px",
              textTransform: "none",
              "&:hover": {
                transform: "scale(1.05)",
                transition: "transform 0.2s",
                borderRadius: 10,
                bgcolor: "primary.light",
                color: "primary.contrastText",
              },
              "&:focus": {
                transform: "scale(1.05)",
                transition: "transform 0.2s",
                borderRadius: 10,
                bgcolor: "primary.light",
                color: "primary.contrastText",
              },
              "&.Mui-selected": {
                color: "primary.contrastText",
                backgroundColor: "primary.main",
                borderRadius: 10,
                "&:hover": {
                  transform: "scale(1.05)",
                  transition: "transform 0.2s",
                  bgcolor: "primary.dark",
                  color: "primary.contrastText",
                },
              },
            }}
          >
            Bug Report
          </Button>
          <Button
            variant="contained"
            onClick={() => handleFeedbackTypeChange("Feature Request", "Submit a Feature Request")}
            sx={{
              width: "100%",
              height: "12.5vh",
              fontSize: "1.5rem",
              flexDirection: "column",
              alignItems: "center",
              borderRadius: 10,
              padding: "20px 24px",
              textTransform: "none",
              "&:hover": {
                transform: "scale(1.05)",
                transition: "transform 0.2s",
                borderRadius: 10,
                bgcolor: "primary.light",
                color: "primary.contrastText",
              },
              "&:focus": {
                transform: "scale(1.05)",
                transition: "transform 0.2s",
                borderRadius: 10,
                bgcolor: "primary.light",
                color: "primary.contrastText",
              },
              "&.Mui-selected": {
                color: "primary.contrastText",
                backgroundColor: "primary.main",
                borderRadius: 10,
                "&:hover": {
                  transform: "scale(1.05)",
                  transition: "transform 0.2s",
                  bgcolor: "primary.dark",
                  color: "primary.contrastText",
                },
              },
            }}
          >
            Feature Request
          </Button>
          <Button
            variant="contained"
            onClick={() => handleFeedbackTypeChange("Contact Us", "Contact Us")}
            sx={{
              width: "100%",
              height: "12.5vh",
              fontSize: "1.5rem",
              flexDirection: "column",
              alignItems: "center",
              borderRadius: 10,
              padding: "20px 24px",
              textTransform: "none",
              "&:hover": {
                transform: "scale(1.05)",
                transition: "transform 0.2s",
                borderRadius: 10,
                bgcolor: "primary.light",
                color: "primary.contrastText",
              },
              "&:focus": {
                transform: "scale(1.05)",
                transition: "transform 0.2s",
                borderRadius: 10,
                bgcolor: "primary.light",
                color: "primary.contrastText",
              },
              "&.Mui-selected": {
                color: "primary.contrastText",
                backgroundColor: "primary.main",
                borderRadius: 10,
                "&:hover": {
                  transform: "scale(1.05)",
                  transition: "transform 0.2s",
                  bgcolor: "primary.dark",
                  color: "primary.contrastText",
                },
              },
            }}
          >
            Contact Us
          </Button>
        </Box>
      ) : (
        <>
          {feedbackType === "Bug Report" && (
            <BugReportModal
              open={modalOpen}
              onClose={handleModalClose}
              onSubmit={() => {}} // Or remove this prop if not needed
              title={modalTitle}
            />
          )}
          {feedbackType === "Feature Request" && (
            <FeatureRequestModal
              open={modalOpen}
              onClose={handleModalClose}
              onSubmit={() => {}} // Or remove this prop if not needed
              title={modalTitle}
            />
          )}
          {feedbackType === "Contact Us" && (
            <ContactUsModal
              open={modalOpen}
              onClose={handleModalClose}
              onSubmit={() => {}} // Or remove this prop if not needed
              title={modalTitle}
            />
          )}
          {feedbackType === "Other" && (
            <ContactUsModal
              open={modalOpen}
              onClose={handleModalClose}
              onSubmit={() => {}} // Or remove this prop if not needed
              title={modalTitle}
            />
          )}
        </>
      )}
    </Box>
  );
};

export default FeedbackForm;
