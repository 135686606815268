import React, { useState, useMemo } from "react";
import {
  Box,
  List,
  TextField,
  Button,
} from "@mui/material";
import { useFacilities } from "../../context/FacilitiesContext";
import FacilityItem from "./FacilityItem";
import { createFacilityMembership } from "../../utils/api/facilityMembershipsApi";
import { useAuth0 } from "@auth0/auth0-react";
import { useSnackbar } from "../BaseComponents/GlobalErrorSnackbar";

const getAssignedFacilityIds = (memberships) => {
  const assignedIds = new Set();

  const collectIds = (facilities) => {
    facilities.forEach(facility => {
      assignedIds.add(facility.id);
      if (facility.children) {
        collectIds(facility.children);
      }
    });
  };

  collectIds(memberships);

  return assignedIds;
};

const getUnassignedFacilities = (facilities, assignedFacilityIds) => {
  return facilities.filter(facility => {
    const isUnassigned = !assignedFacilityIds.has(facility.id);
    const hasUnassignedChildren = facility.children && 
      facility.children.some(child => !assignedFacilityIds.has(child.id));
    return isUnassigned || hasUnassignedChildren;
  }).map(facility => ({
    ...facility,
    children: facility.children ? facility.children.filter(child => !assignedFacilityIds.has(child.id)) : []
  }));
};

const searchFacilitiesByName = (facilities, query) => {
  const lowercaseQuery = query.toLowerCase();
  return facilities.filter(facility =>
    facility.name.toLowerCase().includes(lowercaseQuery)
  );
};

export default function AssignNewFacilities({ detailedUser, onAssign }) {
  const [selectedFacilities, setSelectedFacilities] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const { facilities } = useFacilities();
  const { getAccessTokenSilently } = useAuth0();
  const { handleError, handleSuccess } = useSnackbar();

  const assignedFacilityIds = useMemo(() => getAssignedFacilityIds(detailedUser.facility_memberships), [detailedUser.facility_memberships]);

  const unassignedFacilities = useMemo(() => 
    getUnassignedFacilities(facilities, assignedFacilityIds),
    [facilities, assignedFacilityIds]
  );

  const searchedFacilities = useMemo(() => 
    searchFacilitiesByName(unassignedFacilities, searchQuery),
    [unassignedFacilities, searchQuery]
  );

  const handleFacilityChange = (facilityId, isChecked) => {
    setSelectedFacilities(prevSelected => {
      if (isChecked) {
        return [...new Set([...prevSelected, facilityId])];
      } else {
        return prevSelected.filter(id => id !== facilityId);
      }
    });
  };

  const handleSubmit = async () => {
    try {
      const token = await getAccessTokenSilently();
      const createdMemberships = await Promise.all(
        selectedFacilities.map(facilityId =>
          createFacilityMembership(
            detailedUser.id,
            facilityId,
            {}, // You can set default permissions here if needed
            token
          )
        )
      );
      onAssign(createdMemberships);
      handleSuccess("Facilities assigned successfully");
    } catch (error) {
      console.error("Error assigning facilities:", error);
      handleError(error);
    }
  };

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <TextField
        fullWidth
        placeholder="Search by facility name"
        variant="outlined"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        sx={{ mb: 2 }}
      />
      <Box flexGrow={1} overflow="auto" mb={2}>
        <List>
          {searchedFacilities.map((facility) => (
            <FacilityItem
              key={facility.id}
              facility={facility}
              level={0}
              selectedFacilities={selectedFacilities}
              onFacilityChange={handleFacilityChange}
            />
          ))}
        </List>
      </Box>
      <Box>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={selectedFacilities.length === 0}
        >
          Assign Selected Facilities
        </Button>
      </Box>
    </Box>
  );
}