const BASE_URL = process.env.REACT_APP_BACKEND_URL;

// Fetch all users
export const fetchUsers = async (token) => {
  try {
    const response = await fetch(`${BASE_URL}/api/v1/users`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return await response.json();
  } catch (error) {
    console.error("Error fetching users:", error);
    error.message = "Error fetching users (check console for full error)";
    throw error;
  }
};

// Update user permissions
export const updateUserPermissions = async (userId, facilityIds, permissions, token) => {
  try {
    const response = await fetch(`${BASE_URL}/api/v1/users/${userId}/permissions`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        facility_ids: facilityIds,
        permissions: permissions,
      }),
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return await response.json();
  } catch (error) {
    console.error(`Error updating permissions for user with ID ${userId}:`, error);
    error.message = `Error updating permissions for userID ${userId} (check console for full error)`;
    throw error;
  }
};

// Fetch a specific user by their ID
export const fetchUserById = async (userId, token) => {
  try {
    const response = await fetch(`${BASE_URL}/api/v1/users/${userId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return await response.json();
  } catch (error) {
    console.error(`Error fetching user with ID ${userId}:`, error);
    error.message = `Error fetching userID ${userId} (check console for full error)`;
    throw error;
  }
};

// Search for users based on a query
export const searchUsers = async (searchQuery, token) => {
  try {
    const response = await fetch(`${BASE_URL}/api/v1/users?search=${encodeURIComponent(searchQuery)}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return await response.json();
  } catch (error) {
    console.error(`Error searching for users with query "${searchQuery}":`, error);
    error.message = `Error searching for user ${searchQuery} (check console for full error)`;
    throw error;
  }
};