const BASE_URL = process.env.REACT_APP_BACKEND_URL;

export const fetchProducts = async (token) => {
  try {
    const response = await fetch(`${BASE_URL}/api/v1/products`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return await response.json();
  } catch (error) {
    console.error("Error fetching products:", error);
    throw error;
  }
};


export const searchProducts = async (searchQuery, token) => {
  try {
    const response = await fetch(
      `${BASE_URL}/api/v1/products?search=${encodeURIComponent(searchQuery)}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return await response.json();
  } catch (error) {
    console.error(
      `Error searching for products with query "${searchQuery}":`,
      error
    );
    throw error;
  }
};