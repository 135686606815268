import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Container,
  Paper,
  IconButton,
  Typography,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import { styled } from "@mui/material/styles";
import { useAuth0 } from "@auth0/auth0-react";
import { useSnackbar } from "./BaseComponents/GlobalErrorSnackbar";
import { createUpload } from "../utils/api/uploadsApi";

export default function FileUpload() {
  const [files, setFiles] = useState([]);
  const { getAccessTokenSilently } = useAuth0();
  const [uploading, setUploading] = useState(false);

  const { handleError, handleSuccess } = useSnackbar();
  const handleUploadFiles = useCallback(async () => {
    if (files.length === 0) return;

    setUploading(true);
    let remainingFiles = [...files];

    for (let file of remainingFiles) {
      try {
        const token = await getAccessTokenSilently();
        const response = await createUpload(file, token);
        setFiles(currentFiles => currentFiles.filter(f => f !== file));
        handleSuccess(`${file.name}: ${response.message}`);
      } catch (error) {
        error.message = `${file.name}: ${error.message}`;
        handleError(error);
      }
    }

    setUploading(false);
  }, [files, getAccessTokenSilently, handleError, handleSuccess]);

  const handleDelete = (fileIndex) => {
    setFiles(files.filter((file, index) => index !== fileIndex));
  };

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (acceptedFiles) => {
      setFiles((prevFiles) => [
        ...prevFiles,
        ...acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        ),
      ]);
    },
  });

  return (
    <Container maxWidth="md">
      <Box
        {...getRootProps()}
        sx={{
          height: "200px",
          border: "2px dashed grey",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "20px",
          cursor: "pointer",
          background: isDragActive ? "#f0f0f0" : "#fafafa",
        }}
      >
        <input {...getInputProps()} />
        <CloudUploadIcon sx={{ fontSize: 50 }} />
        <Typography variant="subtitle1">
          {isDragActive
            ? "Drop the files here ..."
            : "Drag 'n' drop some files here, or click to select files"}
        </Typography>
        <Button variant="outlined" sx={{ mt: 2 }}>
          Select Files
        </Button>
      </Box>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>File Name</TableCell>
              <TableCell align="right">File Size</TableCell>
              <TableCell align="right">Remove</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {files.length > 0 ? (
              files.map((file, index) => (
                <StyledTableRow key={index}>
                  <TableCell component="th" scope="row">
                    {file.name}
                  </TableCell>
                  <TableCell align="right">{formatBytes(file.size)}</TableCell>
                  <TableCell align="right">
                    <IconButton onClick={() => handleDelete(index)}>
                      <DeleteIcon color="error" />
                    </IconButton>
                  </TableCell>
                </StyledTableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={3} align="center">
                  No files selected
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
        <Button
          variant="contained"
          color="primary"
          disabled={files.length === 0 || uploading}
          onClick={handleUploadFiles}
          sx={{
            backgroundColor: files.length > 0 ? "primary.main" : "grey.500",
            "&:hover": {
              backgroundColor: files.length > 0 ? "primary.dark" : "grey.500",
            },
          }}
        >
          {uploading ? "Uploading..." : "Submit Files"}
        </Button>
      </Box>
    </Container>
  );
}