import React from 'react';

const Legend = ({ data, colors }) => {
  const legendItems = data.map((item, index) => (
    <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
      <div style={{
        width: '15px',
        height: '15px',
        backgroundColor: colors[item.category],
        marginRight: '10px'
      }}></div>
      <span style={{ fontSize: '14px', fontWeight: 'bold' }}>{item.category}</span>
    </div>
  ));

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
      {legendItems}
    </div>
  );
};

export default Legend;
