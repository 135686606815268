import React, { useState } from "react";
import { 
  Container, 
  Paper, 
  Typography, 
  Grid, 
  Box, 
  FormControl, 
  InputLabel, 
  Select, 
  MenuItem,
  ToggleButton,
  ToggleButtonGroup
} from "@mui/material";
import DonutChart from "../BaseComponents/DataCards/DonutChart";
import Legend from "../BaseComponents/Legend";
import TopFacilities from "./DataCards/TopFacilities";

const AdminDashboard = () => {
  const [selectedCategory, setSelectedCategory] = useState("total");
  const [sortBy, setSortBy] = useState("dollar");

  const colors = {
    Reported: "#2ca02c", // Green
    "Not Reported": "#d62728", // Red
  };

  const donutData = [
    { category: "Reported", value: 45 },
    { category: "Not Reported", value: 30 },
  ];

  const topFacilitiesColors = {
    'Local': "#1f77b4",
    'Regional': "#2ca02c",
    'Not Local': "#ff7f0e",
    'Unknown': "#d62728",
  };

  const topFacilitiesLegendData = Object.entries(topFacilitiesColors).map(([category, color]) => ({
    category,
    value: 0,
    color
  }));

  const categoryMapping = {
    'total': 'Total Spent',
    'local': 'Local',
    'regional': 'Regional',
    'not_local': 'Not Local',
    'unknown': 'Unknown',
  };

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
    if (event.target.value === 'total') {
      setSortBy("dollar");
    }
  };

  const handleSortToggle = (event, newSortBy) => {
    if (newSortBy !== null) {
      setSortBy(newSortBy);
    }
  };

  return (
    <Container maxWidth="lg" sx={{ mb: 4 }}>
      <Typography variant="h4" align="center" sx={{ mb: 4, fontWeight: "bold" }}>
        Admin Overview
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Paper elevation={3} sx={{ p: 3, height: '100%', minHeight: 800 }}>
            <Typography variant="h6" align="center" sx={{ mb: 3, fontWeight: "bold" }}>
              Top Facility Purchases (12 months)
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
              <Legend data={topFacilitiesLegendData} colors={topFacilitiesColors} />
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                  <InputLabel id="category-select-label">Sort By</InputLabel>
                  <Select
                    labelId="category-select-label"
                    id="category-select"
                    value={selectedCategory}
                    label="Sort By"
                    onChange={handleCategoryChange}
                  >
                    {Object.entries(categoryMapping).map(([value, label]) => (
                      <MenuItem key={value} value={value}>{label}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <ToggleButtonGroup
                  value={sortBy}
                  exclusive
                  onChange={handleSortToggle}
                  aria-label="sort by"
                  disabled={selectedCategory === "total"}
                  sx={{ 
                    height: 40, 
                    '& .MuiToggleButton-root': { 
                      px: 2,
                      '&.Mui-selected': {
                        backgroundColor: 'primary.main',
                        color: 'white',
                        '&:hover': {
                          backgroundColor: 'primary.dark',
                        },
                      },
                    },
                  }}
                >
                  <ToggleButton value="dollar" aria-label="sort by dollar">
                    Dollars
                  </ToggleButton>
                  <ToggleButton value="percentage" aria-label="sort by percentage">
                    Percent
                  </ToggleButton>
                </ToggleButtonGroup>
              </Box>
            </Box>
            <Box sx={{ height: 750 }}>
              <TopFacilities 
                colors={topFacilitiesColors} 
                selectedCategory={selectedCategory} 
                sortBy={sortBy} 
              />
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={3} sx={{ p: 3, height: '100%', display: 'flex', flexDirection: 'column' }}>
            <Typography variant="h6" align="center" sx={{ mb: 3, fontWeight: "bold" }}>
              District Reporting
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 2 }}>
              <Legend data={donutData} colors={colors} />
              <Box sx={{ width: '300px', height: '300px' }}>
                <DonutChart data={donutData} colors={colors} showMiddleText={false} />
              </Box>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default AdminDashboard;