import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

export default function Footer() {
  const [open, setOpen] = useState(false);
  const currentYear = new Date().getFullYear();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <AppBar position="static" color="transparent" elevation={1} style={{ marginTop: 'auto', alignItems: "center", bottom: 0 }}>
        <Toolbar>
          <Typography variant="body2">
            © {currentYear} FoodTrails Inc. All rights reserved. <a href="#" onClick={handleClickOpen}>Terms and Conditions</a>
          </Typography>
        </Toolbar>
      </AppBar>
      
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>Terms and Conditions</DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            Geolocation and Geocoding data is provided by <a href="https://openstreetmap.org/copyright">OpenStreetMap</a> and its contributors through <a href="https://www.geoapify.com/terms-and-conditions/">Geoapify</a>.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
