import React, { useState, useEffect, useCallback, useMemo, useRef } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Container,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
} from "@mui/material";
import { useSnackbar } from "../BaseComponents/GlobalErrorSnackbar";
import { fetchUsers, searchUsers } from "../../utils/api/usersApi";
import SearchInput from "../BaseComponents/SearchInput";
import UserPermissionManagement from "./UserPermissionManagement";
import { useFacilities } from "../../context/FacilitiesContext";

const UserRow = React.memo(({ user, onManageUser }) => (
  <TableRow>
    <TableCell>{user.email}</TableCell>
    <TableCell>{`${user.first_name} ${user.last_name}`}</TableCell>
    <TableCell>
      <Button size="small" onClick={() => onManageUser(user)} color="primary">
        Manage User
      </Button>
    </TableCell>
  </TableRow>
));

function UserManagementContent() {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  const { getAccessTokenSilently } = useAuth0();
  const { handleError } = useSnackbar();
  const { facilities } = useFacilities();

  const fetchInProgressRef = useRef(false);

  const fetchUsersData = useCallback(async (query = "") => {
    if (fetchInProgressRef.current) return;
    fetchInProgressRef.current = true;

    try {
      const token = await getAccessTokenSilently();
      const usersData = query ? await searchUsers(token, query) : await fetchUsers(token);
      setUsers(usersData);
    } catch (error) {
      setError(error);
      handleError(error);
    } finally {
      setLoading(false);
      fetchInProgressRef.current = false;
    }
  }, [getAccessTokenSilently, handleError]);

  const handleCloseDialog = () => {
    setSelectedUser(null);
  };

  const handleViewMore = (user) => {
    setSelectedUser(user);
  };

  useEffect(() => {
    const loadUsers = async () => {
      try {
        const token = await getAccessTokenSilently();
        const usersData = await fetchUsers(token);
        setUsers(usersData);
      } catch (error) {
        setError(error);
        handleError(error);
      } finally {
        setLoading(false);
      }
    };

    loadUsers();
  }, [getAccessTokenSilently, handleError]);

  useEffect(() => {
    fetchUsersData();
  }, [fetchUsersData]);

  const handleSearch = useCallback((query) => {
    setSearchQuery(query);
    fetchUsersData(query);
  }, [fetchUsersData]);

  const handleManageUser = useCallback((user) => {
    setSelectedUser(user);
  }, []);

  const userRows = useMemo(() => 
    users.map((user) => (
      <UserRow key={user.id} user={user} onManageUser={handleManageUser} />
    ))
  , [users, handleManageUser]);

  if (loading && users.length === 0) {
    return (
      <Container maxWidth="lg" sx={{ mt: 4, display: "flex", justifyContent: "center" }}>
        <CircularProgress />
      </Container>
    );
  }

  if (error && users.length === 0) {
    return (
      <Container maxWidth="lg" sx={{ mt: 4 }}>
        <Typography color="error">Error loading users: {error.message}</Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>
      <Typography variant="h4" gutterBottom>User Management</Typography>
      <SearchInput
        value={searchQuery}
        onChange={setSearchQuery}
        onSearch={handleSearch}
        placeholder="Search users..."
      />
      <TableContainer component={Paper} sx={{ mt: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Email</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user) => (
              <TableRow
                key={user.id}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.04)" },
                }}
              >
                <TableCell>{user.email}</TableCell>
                <TableCell>{`${user.first_name} ${user.last_name}`}</TableCell>
                <TableCell>
                  <Button
                    size="small"
                    onClick={() => handleViewMore(user)}
                    color="primary"
                  >
                    Manage User
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {selectedUser && (
        <UserPermissionManagement
          user={selectedUser}
          facilities={facilities}
          open={!!selectedUser}
          onClose={() => setSelectedUser(null)}
        />
      )}
    </Container>
  );
}

export default React.memo(UserManagementContent);