import React, { useMemo, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Subheader from "./BaseComponents/Subheader";
import AdminDashboard from "./AuthenticatedAdminPageContents/AdminDashboard";
import FacilitiesContent from "./AuthenticatedAdminPageContents/FacilitiesContent";
import UserManagementContent from "./AuthenticatedAdminPageContents/UserManagementContent";
import ChildComponentLayout from "./BaseComponents/ChildComponentLayout"; 

const tabDetails = [
  { label: "Overview", path: "/admin", component: AdminDashboard },
  {
    label: "Facilities",
    path: "/admin/facilities",
    component: FacilitiesContent,
  },
  {
    label: "Users",
    path: "/admin/users",
    component: UserManagementContent,
  },
];

const AuthenticatedAdminPageContent = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleTabChange = (path) => {
    navigate(path);
  };

  const currentComponent = useMemo(() => {
    const currentTabDetail = tabDetails.find((tab) => tab.path === location.pathname);
    return currentTabDetail && currentTabDetail.component
      ? React.createElement(currentTabDetail.component, { key: location.pathname })
      : null;
  }, [location.pathname]);
  return (
    <div>
      <Subheader tabDetails={tabDetails} onTabChange={handleTabChange} currentPath={location.pathname} />
      <ChildComponentLayout>{currentComponent}</ChildComponentLayout>
    </div>
  );
};

export default React.memo(AuthenticatedAdminPageContent);